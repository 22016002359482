.miniCalendarToggler {
  border: 1px solid #eaecf3;
  border-radius: 12px;
}

.title {
  width: 110;
  white-space: nowrap;
  overflow: "hidden";
  text-overflow: ellipsis;
  border-radius: 4px;
  margin-bottom: 2px;
  padding: 1px 5px;
}

@media (max-width: 875px) and (min-width: 769px) {
  .nowBtn {
    margin-top: 15px !important;
  }
}

@media (max-width: 625px) {
  .nowBtn {
    margin-top: 15px !important;
  }
}

@media (max-width: 1022px) {
  .miniCalendarToggler {
    margin-top: 15px !important;
  }
}
