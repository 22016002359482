.container {
  .title {
    margin-top: 5px; 
    font-weight: 500;
    font-size: 12px;
    color: #69768b;
  }

  .subTitle { 
    margin-bottom: 25px;
    font-weight: 400;
    font-size: 8px;
    color: #69768b;
  }

  .deviceContainer {
    padding: 10px 0;

    .left {
      .icon {
        width: 32.75px;
        height: 32.75px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 4px;
      }
      label {
        font-weight: 500;
        font-size: 11px;
        color: #383941;
        line-height: 10px;
      }
    }
    .right {
      .progress {
        height: 14px;
        border-radius: 4px;
        overflow: hidden;
        position: relative;
        div {
          position: absolute;
          right: 0;
          height: 100%;
        }
      }
      label {
        font-weight: 500;
        font-size: 10px;
        line-height: 12px;
        color: #69768b;
      }
    }
  }
}
