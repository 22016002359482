.dropdown-menu {
  position: absolute;
  width: max-content;
  background-color: #ffffff;
  border: 1.5px solid #f5f6f7;
  box-shadow: 0px 6px 16px rgba(193, 193, 193, 0.25);
  border-radius: 8px;
  z-index: 5;
  list-style-type: none;
  padding: 0;
  margin-top: 40px;

  li {
    cursor: pointer;
    padding: 10px;
    padding-left: 12px;
    font-weight: 400;
    font-size: 12px;
    color: #69768b;
    &:hover {
      background-color: rgba(221, 221, 221, 0.137);
    }

    &:not(:last-child) {
      border-bottom: 0.5px solid #f4f6fa;
    }
  }
}
