.cardContainer {
  border-radius: 8px;
  background-color: #fff;
  box-shadow: 0px 60px 200px rgba(75, 75, 78, 0.2);
}

.disabledCardContainer {
  border-radius: 8px;
  background-color: #f8f8f8;
  box-shadow: 0px 60px 200px rgba(75, 75, 78, 0.2);
}
