.miniCalendarToggler {
  border: 1px solid #eaecf3;
  border-radius: 12px;
}

.title {
  width: 110;
  white-space: nowrap;
  overflow: "hidden";
  text-overflow: ellipsis;
  border-radius: 4px;
  margin-bottom: 2px;
  padding: 1px 5px;
}

.gride-overflow {
  overflow-y: auto;
  height: calc(100vh - 170px);
}

@media (max-width: 1378px) {
  .gride-overflow {
    height: calc(100vh - 210px);
  }
}

@media (min-width: 576px) and (max-width: 769px) {
  .nowBtn {
    margin-top: 10px !important;
  }
}
