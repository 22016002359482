.table {
  font-family: Arial, Helvetica, sans-serif;
  border-collapse: collapse;
  width: 100%;
  margin-top: 20px;
  td {
    border-top: 1px solid #f4f4f4;
    border-bottom: 1px solid #f4f4f4;
    padding: 8px;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    color: #69768b;
  }

  th {
    padding-top: 12px;
    padding-bottom: 12px;
    padding-left: 8px;
    text-align: left;
    background-color: #ffffff;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    color: #383941;
  }
}
.button {
  background: #2f2a89;
  box-shadow: 0px 4px 12px rgba(47, 42, 137, 0.42);
  border-radius: 6px;
  padding-left: 30px;
  padding-right: 30px;
  color: #ffffff;
  border: none;
  padding-top: 5px;
  padding-bottom: 5px;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
}

.usersListOverflow {
  overflow-y: auto;
  height: calc(100vh - 70px);
}
