.backButton {
  width: 26px;
  height: 26px;
  background: #2f2a89;
  box-shadow: 0px 3px 16px rgba(47, 42, 137, 0.16);
  border-radius: 8px;
  color: white;
  cursor: pointer;
}

.avatar {
  width: 78px;
  height: 78px;
  overflow: hidden;
  border-radius: 50%;
  border: solid 5px #fbe4e4;

  img {
    width: 100%;
    height: 100%;
  }
}

.displayName {
  font-style: normal;
  font-weight: 500;
  font-size: 22px;
  color: #383941;
  min-width: 200px;
}

.userName {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  color: #69768b;
}

.contacts {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  color: #69768b;
}

@media (max-width: 480px) {
  .avatar {
    width: 60px;
    height: 60px;
    margin-left: -5px;
  }

  .displayName {
    font-size: 16px;
  }

  .userName {
    font-size: 12px;
  }

  .contacts {
    font-size: 12px;
  }
}