.container {
  .icon {
    width: 32.75px;
    height: 32.75px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 4px;
  }
  .progress {
    height: 14px;
    border-radius: 4px;
    overflow: hidden;
    position: relative;
    div {
      position: absolute;
      right: 0;
      height: 100%;
    }
  }
}
