.checkBoxContainer {
  position: relative;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  padding-left: 25px;

  font-weight: 400;
  font-size: 16px;
  color: #69768b;

  & input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }

  .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 16px;
    width: 16px;
    border-radius: 4px;
    margin-top: 3px;
    background: #ffffff;

    &:after {
      width: 4px;
      height: 8px;
      position: absolute;
      display: none;
      left: 5px;
      top: 3px;
      -webkit-transform: rotate(45deg);
      -ms-transform: rotate(45deg);
      transform: rotate(45deg);
    }
  }

  input:checked ~ .checkmark:after {
    display: block;
  }
}

.checkBoxContainerWithNonColor {
  .checkmark {
    border: 1px solid #b8c1de;

    &:after {
      content: "";
      border: solid black;
      border-width: 0 3px 3px 0;
    }
  }
}

.checkBoxContainerWithColor {
  .checkmark {
    border: 1px solid #eaecf3;

    &:after {
      content: "";
      border: solid #ffffff;
      border-width: 0 2px 2px 0;
    }
  }
}

.checkBoxContainerWithColor input:checked ~ .checkmark {
  background-color: #2f2a89;
}
