.search-wrapper {
  border: 1px solid #eaecf3;
  border-radius: 0 12px 12px 0;
  height: 40px;
  .search-input,
  .search-input:focus {
    border-style: none;
    outline: none;
    height: 36px;
    font-size: 12px;
    &::placeholder {
      font-size: 12px;
    }
  }
}
