.vertical-line {
  border-left: 2px solid #eaecf3;
  height: 95vh;
}

.horizontal-line {
  border: 1.5px solid #eaecf3;
}

.overflowY {
  overflow-y: auto;
  height: calc(100vh - 90px);
}

.limitationInfo {
  background-color: #fffff8;
  border-radius: 8px;
  border: 1px solid #f8fa9c;
}
