.tabContainer {
  background-color: #ffffff;
  border-radius: 6px;
  box-shadow: 0px 8px 17px rgba(47, 42, 137, 0.2);
}

.indicator {
  width: 12px;
  height: 12px;
  border-radius: 50%;
}

.redIndicator {
  background-color: #e74c3c;
}

.grayIndicator {
  background-color: #85929e;
}

.yellowIndicator {
  background-color: #f39c12;
}

.lightGreenIndicator {
  background-color: #2ecc71;
}
