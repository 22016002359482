.space {
  background: #fbfbfb;
  border-bottom: solid 1px #eaeaea;
  font-weight: 600;
  font-size: 12px;
  color: #69768b;
}

.table {
  font-family: Arial, Helvetica, sans-serif;
  border-collapse: collapse;
  width: 100%;

  td {
    border-top: 1px solid #f4f4f4;
    border-bottom: 1px solid #f4f4f4;
    padding: 8px;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    color: #69768b;
    width: calc(100% / 6);
  }

  th {
    padding-top: 12px;
    padding-bottom: 12px;
    padding-left: 8px;
    text-align: left;
    background-color: #ffffff;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    color: #383941;
    width: calc(100% / 6);
  }
}

.device-on {
  color: #3ecd7b;
}
