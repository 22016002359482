.overflowDeviceList {
  overflow-y: auto;
  overflow-x: hidden;
  height: 30vh;
}

.overflowDeviceList::-webkit-scrollbar {
  width: 8px;
  height: 8px !important;
}

.overflowDeviceList::-webkit-scrollbar-thumb {
  background-color: #eaecf3;
  border-radius: 20px;
}
