.arrowBtn {
  border-radius: 8px;
}

.reductionCard {
  border-radius: 8px;
  background: #fff;
  box-shadow: 0px 31px 94px 0px rgba(38, 51, 77, 0.1);
}

.txt {
  color: #29cc39;
}

.ventricleLine {
  position: relative;
}

.ventricleLine::before {
  content: "";
  width: 2px;
  height: 100%;
  background-color: #f3f3f3;
  position: absolute;
  left: 13.5px;
}

.horizontalLine {
  border: 1.5px solid #eaecf3;
}
