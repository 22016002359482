.title {
  font-weight: 600;
  font-size: 32px;
  color: #383941;
}

.subTitle {
  font-weight: 500;
  font-size: 12px;
  color: #69768b;
}
