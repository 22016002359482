.radio-button input[type="radio"] {
  display: none;
}

.radio-circle {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  border: 2px solid #d4d7e1;
  position: relative;
  margin-right: 10px;
  cursor: pointer;
}

.radio-circle::before {
  content: "";
  display: block;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background-color: #ffffff;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) scale(0);
}

.radio-button input[type="radio"]:checked + .radio-circle::before {
  transform: translate(-50%, -50%) scale(1);
}

.radio-button input[type="radio"]:checked + .radio-circle::before {
  background-color: #2f2a89;
}

.radio-button input[type="radio"]:checked + .radio-circle {
  border-color: #2f2a89;
}
