.linkedPlant {
  border: 1px solid #eaecf3;
  background-color: #ffffff;
  border-radius: 12px;
}

.textTruncate {
  width: 100px !important;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
  white-space: nowrap;
}

@media (max-width: 824px) and (min-width: 769px) {
  .activeTab,
  .billingSpaceDropDown {
    width: 100% !important;
    margin-top: 16px !important;
  }
}

.userDashboardOverflow {
  overflow-y: auto;
  height: calc(100vh - 200px);
}

@media (max-width: 991px) {
  .userDashboardOverflow {
    height: calc(100vh - 240px);
  }
}
