.vertical-line {
  border-left: 2px solid #eaecf3;
  height: 70vh;
}

.horizontal-line {
  border: 1.5px solid #eaecf3;
}

.overflowY {
  overflow-y: auto;
  height: calc(100vh - 90px);
}

.requiredMark {
  color: #ff0000;
}
