.button {
  background: #2f2a89;
  box-shadow: 0px 4px 12px rgba(47, 42, 137, 0.42);
  border-radius: 6px;
  padding-left: 30px;
  padding-right: 30px;
  color: #ffffff;
  border: none;
  padding-top: 5px;
  padding-bottom: 5px;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
}

.usersListOverflow {
  overflow-y: auto;
  height: calc(100vh - 70px);
}
