.cardContainer {
  border-radius: 8px;
  background: #fff;
  box-shadow: 0px 31px 94px 0px rgba(38, 51, 77, 0.1);
}

.powerValueGreenColor {
  color: #29cc39;
}

.powerValueRedColor {
  color: #d84560;
}
